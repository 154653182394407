import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import axios from 'axios';

import { PageLayout } from "../../layouts";
import { Block, GetStartedBlock, PersonalityBlock, AboutPartieBlock, EventsGrid, PromoBlock } from '../../components';

const API_ENDPOINT = process.env.API_ENDPOINT;
const API_ENDPOINT_AVATAR_LIST = process.env.API_ENDPOINT_AVATAR_LIST;

/**
 * Mockdata
 */
import { INFLUENCER_DATA } from "../../mockData/influencer-data";

const InfluencerPage = ({ data }) => {
  const [partner] = data?.allMysqlPartnerinvite?.nodes;
  // I was getting a cors error from this.
  // const parsedData = JSON.parse(partner.metadata);
  // const {img} = parsedData;

  const [pageData, setPageData] = useState({});
  const gameId = "53c79124-0f78-42ed-8304-077c0afd686c";
  const pageSize = 6;

  /**
   * Add the FPO image to hero until live data is present.
   * Will be a string stored on the 'metadata' field.
   *
   * ie:
   * const parsedData = JSON.parse(partner.metadata);
   * const {img} = parsedData;
   *
   */
  const partnerData = {
    ...partner,
    imageSrc: "https://cdn.partie.com/downloads/assets/images/invite_graphic_22000.svg",
  };

  /**
   *
   * Get Event Data when the component mounts.
   *
   */
  useEffect(() => {
    /**
     * 
     * Hyrdate Events Grid data.
     * Pass in Search Queries as query params.
     * 
     */
    axios.get(`${API_ENDPOINT}?pageSize=${pageSize}&gameId=${gameId}`)
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          axios.get(`${API_ENDPOINT_AVATAR_LIST}?roomId=${res.data[i].id}`)
            .then((res2) => {
              res.data[i].people = res2.data;
              setPageData({
                eventsTitle: "Call of Duty: Warzone Parties",
                events: res.data
              });
            })
            .catch(error => {
              console.log('Fetch error :: ', error);
            })
        }
      })
      .catch(error => {
        console.log('Fetch error :: ', error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Partie | ${partner.name}`}</title>
        <html lang="en" />
        <meta name="description" content={`${partner.name} invites you to join Partie!`} />
      </Helmet>
      <PageLayout className="influencer-page">
        <PersonalityBlock data={partnerData} />
        <AboutPartieBlock />
        {/* {INFLUENCER_DATA?.promo && <PromoBlock data={INFLUENCER_DATA.promo} pageLink="/clash" pageLinkText="Visit partie.com/clash" />} */}
        <Block centered className="events-block">
          <h2 className="events-block__title">{pageData.eventsTitle}</h2>
          <EventsGrid events={pageData.events} length={pageSize} />
        </Block>
        <GetStartedBlock />
      </PageLayout>
    </>
  );
};

export const query = graphql`
  query InfluencerPage($code: String!) {
    allMysqlPartnerinvite(filter: { code: { eq: $code } }) {
      nodes {
        id
        name
        code
        metadata
      }
    }
  }
`;

export default InfluencerPage;
